* {
  /* border: 1px red solid; */
  /* margin: 0 auto;
  width: 500px; */
  /* --color-brain-pink-dark: #e78d8d; */
  --color-pink-lighter: #EEBCD6;
  --color-pink-light: #DDA6C2;
  --color-pink-dark: #bb8ba4;
  --color-pink-darker: #AB6E8D;
  --color-beige-lighter: #FFFCFA;
  --color-beige-medium-light: #F2ECE7;
  --color-beige-light-new: #F9F5F2;
  --color-beige-light: #fffaf7;
  --color-beige-light-light: #fffcfa;
  --color-beige: rgb(231, 221, 212);
  --color-beige-dark: rgb(231, 221, 212);
  --color-beige-darker: #CDBCAD;
  --color-grey-light: rgb(190, 190, 190);
  --color-grey: #A09892;
  --color-grey-dark: #888079;
  --color-grey-darker: #5C5A58;
  --color-grey-darkest: #444444;
  --color-accent: red;
  --color-logic-green: rgb(78, 206, 66);
  --color-logic-red: rgb(173, 46, 46);

  --color-background-dark: var(--color-beige);
  --color-background-medium: var(--color-beige-medium-light);
  --color-background-light: var(--color-beige-lighter);
  --color-border: var(--color-grey);
  --color-text: var(--color-grey-darkest);
  --color-text-accent: var(--color-pink-dark);

  /* color: var(--color-text); */
  border-color: rgb(233, 233, 7);
}

html,
body {
  height: 100%;
  background-color: var(--color-background-light);
  /* font-family: 'Inter Variable', sans-serif; */
  /* font-family: 'Poppins', sans-serif; */
  /* font-family: 'Ubuntu', sans-serif; */
  font-family: 'Roboto', sans-serif;
}

.coming-soon-badge {
  /* background: linear-gradient(var(--color-beige-lighter), var(--color-beige-lighter)) padding-box,
  linear-gradient(to right, var(--color-pink-lighter), var(--color-pink-dark)) border-box; */
  /* border: 4px solid transparent; */
  border-radius: 1em;
  color: var(--color-beige-lighter);
  background-image: linear-gradient(to right, var(--color-beige-dark), var(--color-beige-darker));
}

.landing-page-header-knowledge-base {
  background-color: var(--color-beige-lighter);
  --color-test: var(--color-beige);
  /* background-image: radial-gradient(circle, var(--color-beige-dark), var(--color-beige-light-light)); */
  /* background-image: conic-gradient(from 90deg, var(--color-beige-light-light), var(--color-beige-medium-light), var(--color-beige-dark)); */
  /* background-image: linear-gradient(to left, var(--color-beige-light-light), var(--color-beige-medium-light), var(--color-beige-dark)); */
}

.landing-page-header-header {
  background-color: var(--color-beige-lighter);
  /* background-image: linear-gradient(to right, var(--color-beige-light-light), var(--color-beige-medium-light), var(--color-beige-dark)); */
  box-shadow: none !important;
}

.landing-page-benefits {
  background-color: var(--color-beige-medium-light);
  /* background-image: linear-gradient(to top, var(--color-beige-light-light), var(--color-beige-medium-light), var(--color-beige-dark)); */
}

.fade-in {
  transition: opacity 1.5s ease;
}

.fade-out {
  opacity: 0;
  transition: opacity 1.5s ease;
}

.text-gradient {
  background: linear-gradient(to right, var(--color-pink-lighter) 0%, var(--color-pink-darker) 100%);
  height: 1.25em;
  background-clip: initial;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
}

.audience-card {
  border-radius: 2em;
  height: 25em;
  /* background-color: var(--color-beige); */
  background-color: var(--color-beige-lighter);
  /* box-shadow: 0 2px 4px 0 rgba(63, 63, 63, 0.2), 0 3px 10px 0 rgba(64, 64, 64, 0.19); */
  /* border: none; */
  /* border: solid 4px linear-gradient(to left, red, yellow); */
  background: linear-gradient(var(--color-beige-lighter), var(--color-beige-lighter)) padding-box,
    linear-gradient(to right, var(--color-pink-lighter), var(--color-pink-dark)) border-box;
  border: 4px solid transparent;
}

.demo-container {
  color: var(--color-beige-lighter)
}

.demo-card {
  border-radius: 2em;
  background-color: var(--color-beige-light-new);
  border: none;
  box-shadow: 0 4px 8px 0 rgba(63, 63, 63, 0.2), 0 6px 20px 0 rgba(64, 64, 64, 0.19);
}



.main {
  margin: 0 auto;
}

/* https://stackoverflow.com/questions/17598996/sass-use-variables-across-multiple-files */

h1 {
  color: var(--color-grey-darkest);
  font-weight: 600;
  font-size: calc(2rem + 2vw);
}

p {
  color: var(--color-text);
  font-weight: normal;
}

h2, h3, h4, h5, h6 {
  color: var(--color-grey-darkest);
  font-weight: 600;
}

.text-secondary {
  color: var(--color-grey-darker)
}

.text-tertiary {
  color: var(--color-grey-dark)
}

.call-to-action {
  background-color: var(--color-background-medium);
}

.call-to-action h4 {
  color: var(--color-grey-darker);
}

.call-to-action p {
  color: var(--color-grey-dark)
}

.feature-text {
  color: var(--color-grey);
}

.learn-more-link {
  color: var(--color-pink-light) !important;
  text-decoration-line: none;

}

.learn-more-link:hover {
  color: var(--color-pink-dark) !important;
}

.navbar-brand {
  font-weight: 400;
  font-size: x-large;
}

.topic-list-horizontal-container {
  overflow-x: auto;
}

.btn-toggle-checked {
  background-color: var(--color-grey) !important;
  color: var(--color-beige-light-light) !important;
}

.btn-toggle-unchecked {
  border-color: var(--color-grey-light);
  border-width: 2px;
  color: var(--color-grey-light);
}

::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  background: transparent;
}

.btn-vote-agree {
  /* color: var(--color-logic-green); */
  color: var(--color-pink-light);

}

.btn-vote-neutral {
  color: var(--color-beige-medium-light);
}

.btn-vote-disagree {
  /* color: var(--color-logic-red); */
  color: var(--color-pink-light);
}

.statement-footer-icon {
  color: var(--color-beige-medium-light);
}

.topic-list-horizontal-card {
  min-height: 100px;
  min-width: 100px;
  max-height: 100px;
  max-width: 100px;
  margin-right: 5px;
}

.topic-card-horizontal {
  background-color: var(--color-background-dark);
  border: none;
  border-radius: 25px;
  min-height: 150px;
  min-width: 150px;
  font-size: smaller;
  text-decoration: none;
}

.topic-card-title {
  font-size: small;
}

a {
  color: var(--color-grey-dark);
  text-decoration: none;
}

a:hover,
a:active {
  color: var(--color-grey-darkest)
}

.navbar-toggler {
  color: var(--color-grey-dark);
  border: none;
}

.btn {
  border-radius: 10px;
}

.btn-secondary {
  border-color: var(--color-grey);
  background: none;
  color: var(--color-grey);
  /* box-shadow: 0 2px 4px 0 rgba(63, 63, 63, 0.2), 0 3px 10px 0 rgba(64, 64, 64, 0.19); */

}

.btn-secondary:hover {
  background: none;
  border-color: var(--color-grey-dark);
  color: var(--color-grey-dark);
}

.btn-primary {
  border: none;
  background-image: linear-gradient(to bottom, var(--color-pink-light), var(--color-pink-dark));
  box-shadow: 0 2px 4px 0 rgba(63, 63, 63, 0.2), 0 3px 10px 0 rgba(64, 64, 64, 0.19);
}

.btn-primary:hover {
  background-image: linear-gradient(to bottom, var(--color-pink-dark), var(--color-pink-darker));
  color: var(--color-beige-light);
}

.list-group-item {
  border: none;
  background: none;
}

.topic-card {
  border: none;
  color: var(--color-text);
  background-color: var(--color-beige);
  border-radius: 20px;
}

.feed {
  /* max-width: 500px; */
  background-color: var(--color-background-light);
}

.sidebar-link-active {
  background-color: var(--color-text) !important;
  font-weight: bolder;
  color: var(--color-background-light);
  border-radius: 0 2em 2em 0 !important;
  margin: 1em 0 0 0;
  padding: 0.25em 0;


}

.sidebar-link-inactive {
  color: var(--color-text);
  font-weight: bold;
  margin: 1em 0 0 0;
  padding: 0.25em 0;


}

.sidebar-icon {
  margin: 0.25em 1em 0.25em 1.25em;
}

.navbar-link-active {
  background-color: var(--color-background-light) !important;
  font-weight: bolder;
  color: var(--color-grey-dark);
  margin: 0em;
  padding: 0.25em;


}

.navbar-link-inactive {
  color: var(--color-grey);
  font-weight: bold;
  margin: 0em;
  padding: 0.25em;
}

.navbar-icon {
  margin: 0.15em;
}

.floating-add-button {
  z-index: 1031;
}

.login-view {
  font-size: medium;
  font-weight: bold;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.react-flow__panel {
  color: var(--color-beige-light);
  padding: 5px 10px;
  background: var(--color-grey-dark);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  border-radius: 15px;
}

.react-flow .react-flow__node {
  justify-content: center;
  align-items: center;
  display: flex;
  font-weight: 400;
}

.react-flow__node-argumentParent {
  color: var(--color-text);
  padding: 20px;
  background: var(--color-beige-dark);
}

.react-flow__node-statement {
  color: var(--color-text);
  padding: 20px;
  background: var(--color-background-dark);
}

.react-flow__node-addStatement {
  color: var(--color-background-light);
  padding: 10px;
  font-size: large;
  font-weight: bolder;
  background: var(--color-pink-light);
}

.react-flow__node-argument {
  text-align: center;
  color: var(--color-text);
  font-size: 14px;
  padding: 20px;
  background: var(--color-background-dark);
}

.node-details {
  color: lightgrey;
  font-size: 4px;
}

.statement-node-textfield {
  background-color: var(--color-background-dark);
  border-color: var(--color-background-dark);
  border: none;
  color: var(--color-text);
  resize: none;
  overflow: hidden;
}

.statement-node-textfield:focus {
  background-color: var(--color-background-dark);
  border: none;
  outline: none;
}

.react-flow .react-flow__handle {
  width: 0px;
  height: 0px;
  border-radius: 5px;
  border-color: var(--color-grey-light);
  background-color: var(--color-grey-light);
}

.react-flow .react-flow__edge path,
.react-flow__connectionline path {
  stroke-width: 3;
  color: var(--color-grey-light);
}


.btn-register-login {
  color: var(--color-beige-light);
  background-color: var(--color-pink-light);
  --bs-btn-hover-color: var(--color-beige-light);
  --bs-btn-hover-bg: var(--color-pink-dark);
  --bs-btn-hover-border-color: var(--color-pink-dark);
}

/* 
.statement-node label {
  display: block;
  color: #777;
  font-size: 12px;
} */

.statement-view {
  width: 500px;
}

.react-flow__node-topic {
  background-color: var(--color-grey-dark);
  color: var(--color-beige-light);
  padding: 10px;
  text-align: center;
  border-radius: 50px;
}

.carousel-control-prev,
.carousel-control-next {
  width: 5%;
}

.node-believed {
  color: var(--color-beige);
  transition: box-shadow 1s;
}

.node-believed:hover {
  color: var(--color-background-dark);
  /* box-shadow: 10px 10px blue; */
  /* transform: scale(102%); */
}

.node-not-believed {
  color: var(--color-beige);
}

.node-text {
  color: var(--color-text);
}

/* .link-color-test {
  color: #1266e4;
} */

.link-understood {
  color: green;
}

.link-negation {
  color: #e41212;
}

.link.positive {
  color: green;
}

.link.positive:hover {
  /* color: #1266e4; */
  fill: rgb(233, 233, 7);
}

.link-default {
  color: grey;
}

.footer {
  background-color: var(--color-grey-darkest);
  /* background:  */
  /* linear-gradient(var(--color-grey) 0 0) padding-box, this is the background */
  /* linear-gradient(to top, var(--color-grey-dark), var(--color-grey) 90%); */
  height: 12em;
  color: var(--color-beige-light);
}

.footer-link {
  color: var(--color-grey-dark);
}

.footer-link:hover {
  color: var(--color-grey-light);
}

.App {
  /* text-align: center;
  background-color: white;
  color: rgb(0, 0, 0); */
  background-color: var(--color-background-light);
}

.navbar {
  background-color: var(--color-background-light);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
}

.navbar-brand {
  color: var(--color-grey-dark);
}

.topic-input {
  background: none !important;
  color: var(--color-beige-light-light) !important;
}

.full-height {
  min-height: 95vh;
}

textarea {
  resize: none;
}

.topic-badge {
  background-color: var(--color-grey) !important;
  color: var(--color-beige-light);
  box-shadow: 0 1px 2px 0 rgba(63, 63, 63, 0.2), 0 1.5px 5px 0 rgba(63, 63, 63, 0.19);
}

.topic-badge-big-inactive {
  background-color: var(--color-grey-light) !important;
  font-size: larger;
}

.topic-badge-big-active {
  background-color: var(--color-grey) !important;
  font-size: larger;
}

.modal-header {
  border: none;
}

.modal-content {
  background-color: var(--color-background-light);
  border: none;
  border-radius: 1.5em;
  color: var(--color-text);
}

.modal-body {
  padding-top: 0;
}

.modal-footer {
  border: none;
}

.statement-text {
  width: 96%;
}

#profile-tab-tab-published-statements.active,
#profile-tab-tab-bookmarks.active {
  color: var(--color-text);
  background-color: var(--color-background-light);
}

#profile-tab-tab-published-statements,
#profile-tab-tab-bookmarks {
  color: var(--color-grey-light);
  background-color: var(--color-background-light);
}

.bi-person-circle {
  color: var(--color-grey-dark);
}

.alert {
  border-radius: 1em;
}

.progress {
  background-color: var(--color-background-medium);
  height: 0.5em;
}

.progress-bar {
  background-color: var(--color-pink-light);
}

.form-control {
  background-color: var(--color-beige-dark);
  border-radius: 25px;
  border: none;
  color: var(--color-beige-light-light);
  font-size: medium;
  font-weight: 600;
}

.form-control:active,
.form-control:focus {
  color: var(--color-beige-light-light);
  background-color: var(--color-beige-dark);
  border: none;
  outline: none;
}

.login-btn {
  width: 100%;
  font-size: larger;
  font-weight: 600;
  border-radius: 25px;
}

.syllogism-view {
  border: solid var(--color-background-dark) 3px;
  border-radius: 4%;
}

.source-card-body {
  border: none;
  background-color: none;
}

.source {
  background-color: var(--color-background-dark);
  box-shadow: 0 1px 2px 0 rgba(63, 63, 63, 0.2), 0 1.5px 5px 0 rgba(64, 64, 64, 0.19);
  color: var(--color-text);
  border: none;
  border-radius: 1em;
}

.source-card {
  border: 3px solid transparent;
  border-radius: 2em;
  box-shadow: 0 2px 4px 0 rgba(63, 63, 63, 0.2), 0 3px 10px 0 rgba(64, 64, 64, 0.19);
  /* background:  */
  /* linear-gradient(var(--color-background-medium) 0 0) padding-box, /*this is the background*/
  /* linear-gradient(to bottom, var(--color-logic-green), var(--color-background-medium) 50%) border-box; */
}

.source-card-contra {
  background:
    linear-gradient(var(--color-beige-medium-light) 0 0) padding-box,
    /*this is the background*/
    linear-gradient(to bottom, var(--color-logic-red), var(--color-beige-medium-light) 50%) border-box;
}

.source-card-pro {
  background:
    linear-gradient(var(--color-background-light) 0 0) padding-box,
    /*this is the background*/
    linear-gradient(to bottom, var(--color-logic-green), var(--color-background-light) 50%) border-box;
}

.source-input {
  color: var(--color-text);
  border-radius: 0.5em;
}

.source-input:active {
  border: none !important;
  color: var(--color-text) !important;
}

.source-input:focus {
  border: none !important;
  color: var(--color-text) !important;
}

.conditions-card-body {
  border: none;
  background-color: none;
}

.conditions-card {
  border: 3px solid transparent;
  border-radius: 2em;
}

.conditions-card-contra {
  box-shadow: 0 2px 4px 0 rgba(63, 63, 63, 0.2), 0 3px 10px 0 rgba(64, 64, 64, 0.19);
  background:
    linear-gradient(var(--color-beige-medium-light) 0 0) padding-box,
    /*this is the background*/
    linear-gradient(to bottom, var(--color-logic-red), var(--color-beige-medium-light) 50%) border-box;
}

.conditions-card-pro {
  box-shadow: 0 2px 4px 0 rgba(63, 63, 63, 0.2), 0 3px 10px 0 rgba(64, 64, 64, 0.19);
  background:
    linear-gradient(var(--color-beige-medium-light) 0 0) padding-box,
    /*this is the background*/
    linear-gradient(to bottom, var(--color-logic-green), var(--color-beige-medium-light) 50%) border-box;
}

#intro-text {
  min-height: 25vh;
}

.navigation-sidebar {
  background-color: var(--color-beige-medium-light);
  min-height: 100vh;
  position: sticky;
}

.syllogism-footer {
  border-top-color: var(--color-background-dark);
}

/* For d3-Tree: */
.node circle {
  fill: #fff;
  /* stroke: steelblue; */
  stroke-width: 3px;
}

.title {
  color: var(--color-text);
}

.node text {
  font: 16px sans-serif;
}

.bi-lightbulb:hover {
  fill: rgb(236, 190, 63);
}

.bi-plus-circle-fill {
  fill: var(--color-grey-dark);
  background-color: var(--color-background-light);
  border-radius: 50%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

/* .icon {
  color: red;
  fill: red;
}

.icon-understand:hover {
  fill: red;
} */

.btn-icon-text {
  background-color: none !important;
  color: var(--color-beige-darker);
}

.btn-icon-text:hover {
  background-color: none !important;
  color: var(--color-grey-dark);
}

/* .btn-icon-text-icon {
  background-color: inherit !important;
} */

.btn-icon {
  background: none;
  color: var(--color-grey-dark);
  box-shadow: none;
}

.btn-icon:hover {
  background: none;
  color: var(--color-grey-light)
}

.icon-believe {
  fill: red;
  color: red;
  background-color: red;
}

.bi-heart-fill {
  fill: #e41212;
}

.bi-heart-fill:hover {
  fill: #ad0f0f;
}

.bi-heart:hover {
  fill: #494949;
}

.statement {
  background-color: var(--color-background-dark);
  background-image: linear-gradient(to bottom, var(--color-background-dark), var(--color-beige-dark));
  color: var(--color-text);
  border: none;
  border-radius: 1em;
  box-shadow: 0 1px 2px 0 rgba(63, 63, 63, 0.2), 0 1.5px 5px 0 rgba(63, 63, 63, 0.19);
}

.statement-header {
  /* border-radius: 1em 1em 0em 0em; */
  border: none;
  background-color: unset;
}

.statement-footer {
  /* border-radius: 0em 0em 1em 1em; */
  border: none;
  background-color: unset;
}

.statement-options-dropdown {
  padding: 0;
  color: var(--color-grey);
  background: none;
  border-color: none;
  vertical-align: top;
}

.statement-options-dropdown:hover {
  color: var(--color-grey-dark);
  background: none;
}


.statement-options-dropdown:focus {
  color: var(--color-grey-dark);
  background: none;
}

.statement-options-dropdown.show {
  color: var(--color-grey-dark);
  background: none;
}

.statement-options-dropdown:checked {
  color: var(--color-grey-dark);
  background: none;
}

.dropdown-toggle::after {
  display: none !important;
}

.statement-content-input {
  background-color: unset;
}

/* .statement:hover {
  transform: scale(1.02);
  box-shadow: 0 10px 20px rgba(0, 0, 0, .12), 0 4px 8px rgba(0, 0, 0, .06);
} */

.conflict-badge {
  vertical-align: middle;
  /* background-color: rgb(230, 110, 110) !important; */
  /* color: var(--color-background-dark); */
}

.conflict-badge-critical {
  border: 0.2em solid var(--color-logic-red);
  background-color: rgba(230, 110, 110, 0.363) !important;
  color: var(--color-logic-red);
}

.conflict-badge-noconflict {
  border: 0.2em solid var(--color-logic-green);
  background-color: rgba(115, 207, 119, 0.363) !important;
  color: var(--color-logic-green);
}

.conflict-badge-major {
  border: 0.2em solid var(--color-logic-red);
  background-color: rgba(230, 110, 110, 0.363) !important;
  color: var(--color-logic-red);

  /* background-color: rgb(233, 174, 106) !important;
  color: var(--color-text); */

  /* background-color: rgb(221, 154, 76) !important;
  color: var(--color-background-light); */
}

.conflict-badge-minor {
  border: 0.2em solid rgb(211, 137, 52);
  background-color: rgba(211, 137, 52, 0.363) !important;
  color: rgb(211, 137, 52);

  /* background-color: rgb(233, 174, 106) !important;
  color: var(--color-text); */

  /* background-color: rgb(221, 154, 76) !important;
  color: var(--color-background-light); */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}